@import "~@blueprintjs/core/lib/scss/variables";

.page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
}


.header, .footer {
  padding: 8px 1em;

  h1, h2, h3, h4, h5, h6 {
    margin: .25em;
    color: $dark-gray5;
  }
}

.header {
  display: flex;
  align-items: center;
  background-color: $light-gray4;
  border-bottom: 1px solid $light-gray1;

  // :global(.#{$ns}-heading) {
  //   @if not ($ns == "bp3") {
  //     @error "Expecting BluePrint version 3"
  //   }
  //  // Override Blueprint default margin for headers
  //  margin-bottom: 0;
  // }

  &.csdFlow {
    background-image: url("../../../assets/images/CSD-Flow-Blue.svg");
    background-repeat: no-repeat;
    background-size: 560px;
    background-position: calc(100vw - 160px) 5px;
  }
}


.footer {
  background-color: $light-gray4;
}

:global(.#{$ns}-dialog) {
  .header {
    padding-top: 0;
    padding-bottom: 0;

    // h1, h2, h3, h4, h5, h6 {
    //   font-size: unset;
    // }
  }
}


.content {
  padding: 2em 1em 1em 1em;
  flex: 1 1 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  //border: dotted 2px red;
}


.section {
  margin: 2em 0;

  .sectionHeader {
    //padding: 0;
    margin: 1em 0;
    display: block;
    //border-bottom: solid 1px $light-gray1;

    // h1, h2, h3, h4, h5, h6 {
    //   //font-weight: bold;
    // }
  }
}

