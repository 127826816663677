@import "~@blueprintjs/core/lib/scss/variables";
@import "../../assets/styles/variables.scss";


.pageHeader {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 2em 1em;

  .title {
    padding-left: 1em;
    color: $csd-blue;
  }
}


.errorCallout {
  max-width: 800px;
  margin: 2em;
  margin-left: auto;
  margin-right: auto;

  .header {
    margin: 1em 0;
    h4 {
      color: $red2;
    }
  }

  .message {
    flex: 1;
    background-color: rgba($red5, 0.2);
    box-shadow: inset 0px 0px 2px $csd-orange;
    border-radius: 4px;
    padding: 1em;
    color: $red1;
  }
}


.welcome {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1024px;

    .header {
      margin: 0 0 1em 0;
      font-family: verdana, serif;
      font-size: 20pt;
      font-weight: bolder;
    }

    .callout {
      margin-top: 2em;
      max-width: 600px;
      background-color: lighten($blue5, 30%); // avoiding alpha, so that csd-flow graphic doesn't show through
    }

  }
}

