@import "~@blueprintjs/core/lib/scss/variables";
@import "../../../index.scss";


table.dataTable {
  @include tableStyles;

  thead {
    th {
      &.sorted {
        cursor: pointer;

        &.sortAsc, &.sortDesc {
          background-color: $blue1;
        }
      }

      .header {
        display: flex;
        align-items: center;

        span[icon] {
          padding-left: 4px;
        }
      }
    }
  }
}

.footer {
  color: $gray1;
  display: flex;
  padding: 4px;
  font-size: smaller;

  .pager {
    .pagerButton {
      color: $pt-intent-primary;
      font-size: small;
    }
  }
}

