@import "~@blueprintjs/core/lib/scss/variables";

.requiredFieldLabel {
  background: $pt-intent-danger;
  border-radius: 50%;
  display: inline-block;
  height: 5px;
  width: 5px;
  margin-left: 4px;
  position: relative;
  top: -2px;
}


// .formPart .requiredField::after {
//   //content: "\2022";
//   //font-size: 1.5em;
//   position: relative;
//   top: .1em;
//   color: $pt-intent-danger;
// }
