
.csdBlueFlow {
  background-image: url("../../assets/images/CSD-Flow-Blue.svg");
  //background-color: #d0d0dc;
  background-repeat: no-repeat;
  background-size: 100% 200%;
  background-position: calc(100vw - 400px) calc(100vh - 700px);
  position: absolute;
  width: 100%;
  height: 100%;
}

.csdPurpleFlow {
  background-image: url("../../assets/images/CSD-Flow-Purple.svg");
  //background-color: #d0d0dc;
  background-repeat: no-repeat;
  background-size: 100% 200%;
  background-position: calc(100vw - 590px) calc(100vh - 760px);
  position: absolute;
  width: 100%;
  height: 100%;
}
