@import "@blueprintjs/core/lib/scss/variables";

.panel {
  margin: 10px 0;

  &:not(:first-child) {
    margin-top: 50px;
  }

  border-radius: $pt-border-radius;
  box-shadow: 0 0 0px 1px $light-gray1, 1px 1px 3px $light-gray2;
  &.open {
    box-shadow: 0 0 0px 1px $light-gray1, 1px 1px 3px 2px $light-gray2;
  }

  .header {
    font-size: larger;
    color: $dark-gray4;
    padding: 2px 4px;
    background-color: $light-gray2;
    border-radius: $pt-border-radius $pt-border-radius 0 0;
    display: flex;
    align-items: center;
    align-content: center;
    user-select: none;
    cursor: pointer;

    .icon {
      margin-right: 4px;
    }
  }

  &.disabled {
    .header {
      cursor: no-drop;
      color: $gray2;
    }
  }

  .content {
    padding: 10px;

    &.placeHolder {
      color: $gray3;
      font-size: smaller;
    }
  }



}


