@import "~@blueprintjs/core/lib/scss/variables";

.formDialog {
  padding-top: $pt-border-radius;
  padding-bottom: $pt-border-radius;
}


.form {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  flex: 1;
  // display: flex;
  // flex-direction: column;

  .content {
    padding: 1em;
    //flex: 1;
    //border: dotted 2px red;
  }
}

.header {
  padding: 1em;
  //border: dotted 2px green;
}

.footer {
  padding: 0.5em 1em;
  border-top: solid 1px #ccc;
  box-shadow: 0 0px 3px #ddd;
  button {
    min-width: 80px;
    margin: 0 1em;
  }
}

.printHost {
  label {
    font-size: smaller;
  }

  .fieldValue {
    //color: $blue1;
    color: #0066ff;
  }
}


.formPart {
  width: 100%;
  //justify-content: space-between;
  //border: solid red 1px;

  .paragraph {
    padding: 0.5em 0;
  }


  &.section {
    .horizontal {
      .formPart:not(:first-child) {
        padding-left: 1em;
      }
    }
  }

  &.checkboxGroup {
    .checkboxItems {
      // Undo default margin of Bulma CSS `columns`
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      padding: 0 0.75em;

      &.error {
        border: solid 1px $pt-intent-danger;
        border-radius: $pt-border-radius;
      }
    }
  }

  &.signature {
    //width: 100%;

    //$sigCanvasMinHeight: 120px;
    canvas
    {
      border-radius: $pt-border-radius;
      box-shadow: $pt-input-box-shadow;
      background: #fff;
    }

    &.disabled canvas {
      background: rgba(200, 200, 200, 0.3);
      box-shadow: none;
    }

    &.error canvas
    {
      box-shadow:  inset 0 0 0 1px $pt-intent-danger;
      // border: solid 1px $pt-intent-danger;
    }

    &.print {
      canvas {
        box-shadow: none;
        border-bottom: dotted 1px #333;
    }}
  }


  & .errorMsg {
    font-size: smaller;
    // margin: 1em 0; // Note: can't use margin.  See comment in ErrorMessagepart code.
    padding: 2px 1em;
    color: $red2;
    background-color: rgba($red5, 0.15);
    border: solid 1px rgba($red5, 0.15);
    border-left: solid 4px rgba($red2, 0.15);
    border-right: solid 4px rgba($red2, 0.15);

    & p {
      margin-bottom: 0;
    }
  }
} // end: FormPart



