
@import "~@blueprintjs/core/lib/scss/variables";

.section {
  margin-bottom: 1em;

  .header {
    color: $gray1;
    margin-bottom: 1.5em;
    border-left: 4px solid $light-gray3;
    padding-left: 1em;

    h1, h2, h3, h4, h5, h6 {
      font-weight: bolder;
      padding-left: 0.5em;
      display: inline;
    }

    .subtitle {
      margin: 0.5em 0px;
    }
  }

}
