@import "~@blueprintjs/core/lib/scss/variables";

.navBar {
  display: flex;
  justify-content: space-between;
}


.navLink {
  color: $gray2;
  padding: 0 1em;
  //border: solid 1px $pt-divider-black;
  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    //box-shadow: inset 0 0 10px $light-gray2;
    box-shadow: inset 0 -4px 0px $light-gray3;
    text-decoration: none;
  }

  &.active {
    color: $gray1;
    //color: unset;
    background-color: $light-gray5;
    box-shadow: inset 0 0 10px $light-gray3;
    box-shadow: inset 0 -4px 0px $light-gray1;
  }
}

.page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
}


.content {
  flex: 1;
  overflow: auto;
}
